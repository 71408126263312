"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerEventType = void 0;
/**
 * These events are cloned from top's PlayerEventType to maintain existing logic/compatibility
 * across the app.  They can diverge from top if we want them to, but then we'll need to map that behavior
 * across all video player implementations.
 */
var PlayerEventType;
(function (PlayerEventType) {
    /**
     * When the player encounters an error that requires interaction
     *
     * @see [[PlayerErrorResult]]
     */
    PlayerEventType["Player_Error"] = "playerError";
    /**
     * When the player APIs can be used and start to play content
     *
     * @see [[BasePlayerResult]]
     */
    PlayerEventType["Player_Ready"] = "playerReady";
    /**
     * When content is ready to be played
     *
     * @see [[BasePlayerResult]]
     */
    PlayerEventType["Content_Beginning"] = "contentBeginning";
    /**
     * When the state of the media player has changed
     *
     * @see [[VideoEngineMediaStateChangedResult]]
     */
    PlayerEventType["Media_State_Changed"] = "mediaStateChanged";
    /**
     * When the media player is ready to accept commands and play content
     *
     * @see [[BaseVideoEngineResult]]
     */
    PlayerEventType["Media_Ready"] = "mediaReady";
    /**
     * When media playback is blocked due to an error returned from the play promise
     *
     * @see [[VideoEngineMediaBlockedResult]]
     */
    PlayerEventType["Media_Blocked"] = "mediaBlocked";
    /**
     * When the media player has loaded content manifest
     *
     * @see [[VideoEngineMediaLoadedResult]]
     */
    PlayerEventType["Media_Loaded"] = "mediaLoaded";
    /**
     * When the media player has loaded the manifest and is actually playing content
     *
     * @see [[BaseVideoEngineResult]]
     */
    PlayerEventType["Media_Started"] = "mediaStarted";
    /**
     * When the media player has started buffering content
     *
     * @see [[BaseVideoEngineResult]]
     */
    PlayerEventType["Media_Buffering_Started"] = "mediaBufferingStarted";
    /**
     * When the media player has finished buffering content
     *
     * @see [[BaseVideoEngineResult]]
     */
    PlayerEventType["Media_Buffering_Finished"] = "mediaBufferingFinished";
    /**
     * When the media player has finished a seek command
     *
     * @see [[VideoEngineMediaSeekingResult]]
     */
    PlayerEventType["Media_Seeking_Finished"] = "mediaSeekingFinished";
    /**
     * When the media player has started downloading segments in a new bitrate
     *
     * @see [[VideoEngineMediaRequestProfileChangedResult]]
     */
    PlayerEventType["Media_Request_Profile_Changed"] = "mediaRequestProfileChanged";
    /**
     * When the media player has started displaying content in a new bitrate
     *
     * @see [[VideoEngineMediaProfileChangedResult]]
     */
    PlayerEventType["Media_Profile_Changed"] = "mediaProfileChanged";
    /**
     * When the playhead for the media player has updated
     *
     * @see [[VideoEngineMediaTimeChangedResult]]
     */
    PlayerEventType["Media_Time_Changed"] = "mediaTimeChanged";
    /**
     * When the media player has paused content playback
     *
     * @see [[BaseVideoEngineResult]]
     */
    PlayerEventType["Media_Paused"] = "mediaPaused";
    /**
     * When the media player has resumed content playback
     *
     * @see [[BaseVideoEngineResult]]
     */
    PlayerEventType["Media_Resumed"] = "mediaResumed";
    /**
     * When the media player content playback is complete
     *
     * @see [[BaseVideoEngineResult]]
     */
    PlayerEventType["Media_Finished"] = "mediaFinished";
    /**
     * When the media player has an issue with content playback
     *
     * @see [[VideoEngineMediaErrorResult]]
     */
    PlayerEventType["Media_Error"] = "mediaError";
    /**
     * When a segment is played back
     *
     * @see [[SegmentPlaybackEvent]]
     */
    PlayerEventType["Segment_Playback"] = "SegmentPlayback";
    /**
     * When the state of ads has changed
     *
     * @see [[VideoEngineAdStateChangedResult]]
     */
    PlayerEventType["Ad_State_Changed"] = "adStateChanged";
    /**
     * When an ad break is loading and preparing to play
     *
     * @see [[VideoEngineAdResult]]
     */
    // TODO:  I think I can probably remove this and replace it with Ad_Started?
    PlayerEventType["Ad_Starting"] = "adStarting";
    /**
     * When an ad break has begun to play
     *
     * @see [[VideoEngineAdResult]]
     */
    PlayerEventType["Ad_Started"] = "adStarted";
    /**
     * When an individual ad creative has begun to play
     *
     * @see [[VideoEngineAdCreativeResult]]
     */
    PlayerEventType["Ad_Creative_Started"] = "adCreativeStarted";
    /**
     * When an individual ad creative has finished
     *
     * @see [[VideoEngineAdCreativeResult]]
     */
    PlayerEventType["Ad_Creative_Ended"] = "adCreativeEnded";
    /**
     * When an ad break has finished
     *
     * @see [[VideoEngineAdResult]]
     */
    PlayerEventType["Ad_Finished"] = "adFinished";
    /**
     * When an error occurs with ads
     *
     * @see [[VideoEngineAdErrorResult]]
     */
    PlayerEventType["Ad_Error"] = "adError";
    /**
     * Emitted when an ad blocker may be in effect and blocking ads
     *
     * @see {@link TubAdBlockedEvent}
     */
    PlayerEventType["Ad_Blocked"] = "adblocked";
    /**
     * When the state of captions has changed
     *
     * @see [[CCState]]
     * @see [[CCStateChangedResult]]
     */
    PlayerEventType["Captions_State_Changed"] = "captionsStateChanged";
    /**
     * When a subtitle track has been added
     *
     * @see [[CaptionTrackResult]]
     */
    PlayerEventType["Caption_Track_Added"] = "captionTrackAdded";
    /**
     * When a subtitle track has been removed
     *
     * @see [[CaptionTrackResult]]
     */
    PlayerEventType["Caption_Track_Removed"] = "captionTrackRemoved";
    /**
     * When a metadata cue has become `Processed`
     *
     * @see [[CueProcessedResult]]
     */
    PlayerEventType["Cue_Processed"] = "cueProcessed";
    /**
     * When a metadata cue has become `Activated`
     *
     * @see [[CueActivatedResult]]
     */
    PlayerEventType["Cue_Activated"] = "cueActivated";
    /**
     * When the content is complete
     *
     * @see [[BasePlayerResult]]
     */
    PlayerEventType["Content_Completed"] = "contentCompleted";
    /**
     * When either content errors, completes, or is interrupted
     *
     * @see [[BasePlayerResult]]
     */
    PlayerEventType["Content_Ended"] = "contentEnded";
    /**
     * Occurs immediately before the player begins its 'destroy' process.
     */
    PlayerEventType["Destroying"] = "destroying";
    PlayerEventType["Warning"] = "warning";
    PlayerEventType["Metadata_Parsed"] = "mediaTimedMetadataParsed";
    PlayerEventType["Metadata_Changed"] = "Metadata_Changed";
    PlayerEventType["Video_Adaptation"] = "Video_Adaptation";
    PlayerEventType["Audio_Adaptation"] = "Audio_Adaptation";
    PlayerEventType["Download_Finished"] = "Download_Finished";
    PlayerEventType["Segment_Request_Finished"] = "Segment_Request_Finished";
    PlayerEventType["Video_Quality_Added"] = "Video_Quality_Added";
    PlayerEventType["Video_Quality_Removed"] = "Video_Quality_Removed";
    PlayerEventType["Audio_Quality_Added"] = "Audio_Quality_Added";
    PlayerEventType["Audio_Quality_Removed"] = "Audio_Quality_Removed";
    PlayerEventType["Target_Latency_Changed"] = "Target_Latency_Changed";
    PlayerEventType["Latency_Mode_Changed"] = "Latency_Mode_Changed";
})(PlayerEventType = exports.PlayerEventType || (exports.PlayerEventType = {}));
