import branch, { InitOptions } from 'branch-sdk';
import { isProdDomain } from './assets';
import Monitor from './monitor';
import { logger } from 'logger';
import { getGppBoolean } from './privacy';

const LOG = logger.getLogger('branch-sdk');

export function initBranch() {
  LOG.debug('Initializing Branch');

  const branchKey = (isProdDomain() ? process.env.BRANCH_SDK_KEY_RELEASE : process.env.BRANCH_SDK_KEY_DEBUG) as string;
  const options: InitOptions = { no_journeys: true };

  branch.init(branchKey, options, (error, data) => {
    if (error) return Monitor.sendError(error);

    LOG.debug('Branch initialization successful!');
    LOG.debug('Branch sessionData', data);

    branch.disableTracking(getGppBoolean());
  });
}
