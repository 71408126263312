import {
  allowedEventsStore,
  appConfigIndicatorStore,
  appReady,
  bracketIncompleteStore,
  bracketLockStore,
  connectedFullscreenStore,
  connectedMultigameStore,
  enableBracketSponsorStore,
  enableMultiGameStore,
  excitementAlertsBrandedToastStore,
  freeviewTimePersistedStore,
  gameSwitcherAllowedStore,
  internalExcitementStore,
  liveVideoCueIdStore,
  liveVideoCueStore,
  montageChannelGuideStore,
  montageSponsorStore,
  multiGameActiveStore,
  qrcodeCBSStore,
  reportVideoErrorMetaStore,
  scheduleSlateStore,
  sponsorMultiStore,
  statsActiveStore,
  statsInactiveGamesStore,
  tournamentYearStore,
  videoAvalStore,
  videoPlatformStore,
} from 'apolloGraphql/mml-apollo-cache';
import Axios from 'axios';
import { logger } from 'logger';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { VIDEO } from 'static/video-store';
import { AdsConfig } from 'types/configs/ads-config';
import { AppConfig } from 'types/configs/app-config';
import { BillboardsConfig } from 'types/configs/billboard-config';
import { ControlsConfig } from 'types/configs/controls-config';
import { Device, getDevice } from 'utils/getDevice';
import loadScript from 'utils/loadScript';
import { Monitor as monitor } from 'utils/monitor';
import { getWebOSApi } from 'utils/webos/webOSApi';
import { PerformanceFeatureFlagUtil } from 'utils/PerformanceFeatureFlagUtil';
import { arraysAreEqual } from 'utils/arrayUtils';
import { MediaJSONPlatform } from 'video-api';
import usePollInterval from 'hooks/usePollInterval';
import { useForcedRefetch } from 'utils/webos/useForcedRefetch';

const LOG = logger.getLogger('useContextSetup');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockWebos4DeviceInfo = {
  modelName: 'modelName',
  version: '1.2.3',
  versionMajor: 1,
  versionMinor: 2,
  versionDot: 3,
  sdkVersion: '4.0.0',
  screenWidth: 1280,
  screenHeight: 720,
};

export interface UseContextSetupProps {
  adsConfig: AdsConfig | undefined;
  appConfig: AppConfig | undefined;
  appConfigUrl: string | undefined;
  appControlsFetchCounter: number;
  appControlsPollingEnabled: boolean;
  billboardsConfig: BillboardsConfig | undefined;
  boxscoreFetchCounter: number;
  boxscorePollingEnabled: boolean;
  currentFetchCounter: number;
  currentPollingEnabled: boolean;
  device: Device;
  setAdsConfig: Dispatch<SetStateAction<AdsConfig | undefined>>;
  setAppConfig: Dispatch<SetStateAction<AppConfig | undefined>>;
  setAppConfigUrl: Dispatch<SetStateAction<string | undefined>>;
  setAppControlsFetchCounter: Dispatch<SetStateAction<number>>;
  setAppControlsPollingEnabled: Dispatch<SetStateAction<boolean>>;
  setBillboardsConfig: Dispatch<SetStateAction<BillboardsConfig | undefined>>;
  setBoxscoreFetchCounter: Dispatch<SetStateAction<number>>;
  setBoxscorePollingEnabled: Dispatch<SetStateAction<boolean>>;
  setCurrentFetchCounter: Dispatch<SetStateAction<number>>;
  setCurrentPollingEnabled: Dispatch<SetStateAction<boolean>>;
  setWebOSDeviceInfo: Dispatch<SetStateAction<DeviceInfo | undefined>>;
  webosDeviceInfo: DeviceInfo | undefined;
}

const FALLBACK_CONFIG_URL = `https://mmldata.ncaa.com/mml/2025/configs/dev/v1/r1d2live4/`;
const DEFAULT_CONFIG_URL = process.env.APP_CONFIG ?? FALLBACK_CONFIG_URL;

const device = getDevice();

export function useContextSetup(): UseContextSetupProps {
  const [currentPollingEnabled, setCurrentPollingEnabled] = useState(true);
  const [boxscorePollingEnabled, setBoxscorePollingEnabled] = useState(true);
  const [appControlsPollingEnabled, setAppControlsPollingEnabled] = useState(true);
  const [currentFetchCounter, setCurrentFetchCounter] = useState<number>(0);
  const [boxscoreFetchCounter, setBoxscoreFetchCounter] = useState<number>(0);
  const [appControlsFetchCounter, setAppControlsFetchCounter] = useState<number>(0);
  const [appConfigUrl, setAppConfigUrl] = useState<string>();
  const [appConfig, setAppConfig] = useState<AppConfig>();
  const [adsConfig, setAdsConfig] = useState<AdsConfig>();
  const [billboardsConfig, setBillboardsConfig] = useState<BillboardsConfig>();
  const [webosDeviceInfo, setWebOSDeviceInfo] = useState<DeviceInfo>();
  const [paramsCb, setParamsCb] = useState<string>();

  useEffect(() => {
    if (device !== Device.lgtv) return;

    const onWebosSdkLoaded = () => {
      getWebOSApi()
        ?.getDeviceInfo()
        .then((deviceInfo) => {
          PerformanceFeatureFlagUtil.webosDeviceInfo = deviceInfo;
          setWebOSDeviceInfo(deviceInfo);
        })
        .catch((error) => {
          const errorMessage = `An error occurred while retrieving the webos device info: ${error}`;
          LOG.error(errorMessage);
          monitor.sendError(errorMessage);
        });
    };
    loadScript('assets/vendor/webOSTV.js').then(() => onWebosSdkLoaded());
  }, []);

  const fetchAppConfig = useCallback(async () => {
    try {
      const urlParams = new URLSearchParams(self.location.search);
      const env = urlParams.get('env');
      const appConfigUri = `${env ?? DEFAULT_CONFIG_URL}appConfig_${device}.json`;
      setAppConfigUrl(appConfigUri);
      const cb = Math.random().toString(36).substring(5);
      setParamsCb(cb);
      const params = { cb };
      const configResult = await Axios.get<AppConfig>(appConfigUri, { params });

      if (env) {
        setAppConfigAndStore(configResult.data);
        return configResult.data;
      } else {
        const defaultEnvironment = configResult.data.environments.find((environment) => environment.default);
        const splitUrl = DEFAULT_CONFIG_URL.split('v1/').pop();
        const isTargetEnv = splitUrl ? splitUrl.length > 0 : false;
        if (defaultEnvironment && !isTargetEnv) {
          LOG.info('Fetching default config');
          setAppConfigUrl(defaultEnvironment.url);
          const configResult = await Axios.get<AppConfig>(defaultEnvironment.url, { params });
          setAppConfigAndStore(configResult.data);
          return configResult.data;
        } else {
          setAppConfigAndStore(configResult.data);
          return configResult.data;
        }
      }
    } catch (error) {
      LOG.error('ERROR:', error);
      return null;
    }
  }, []);

  const { execute: fetchControlsConfig } = usePollInterval(
    useCallback(async () => {
      if (appConfig && paramsCb) {
        const previousAppConfigIndicator = appConfigIndicatorStore();
        const params = { cb: paramsCb };
        const controlsResult = await Axios.get<ControlsConfig>(appConfig.feature_endpoints.app_controls.href, {
          params,
        });
        setControlsConfig(controlsResult.data);
        // here we set the initial refresh cue
        VIDEO.refreshCue = controlsResult.data[device].video.live.cue ?? 0;
        // refresh the appConfig if the controlsConfig indicates to us that we should do so.
        if (previousAppConfigIndicator && controlsResult.data[device].config !== previousAppConfigIndicator) {
          appConfigIndicatorStore(controlsResult.data[device].config);
          fetchAppConfig();
        }
      }
    }, [appConfig, fetchAppConfig, paramsCb]),
    (appConfig?.feature_endpoints.app_controls.poll_interval ?? 0) * 1000,
  );

  const { execute: fetchAdsConfig } = usePollInterval(
    useCallback(async () => {
      if (appConfig && paramsCb) {
        const params = { cb: paramsCb };
        const adsConfigResult = await Axios.get<AdsConfig>(appConfig.feature_endpoints.ads_config.href, { params });
        setAdsConfig(adsConfigResult.data);
      }
    }, [appConfig, paramsCb]),
    (appConfig?.feature_endpoints.ads_config.poll_interval ?? 0) * 1000,
  );

  const { execute: fetchBillboardsConfig } = usePollInterval(
    useCallback(async () => {
      if (appConfig && paramsCb) {
        const params = { cb: paramsCb };

        const billboardsConfigResult = await Axios.get<BillboardsConfig>(appConfig.feature_endpoints.billboards.href, {
          params,
        });
        setBillboardsConfig(billboardsConfigResult.data);
      }
    }, [appConfig, paramsCb]),
    (appConfig?.feature_endpoints.billboards.poll_interval ?? 0) * 1000,
  );

  function setAppConfigAndStore(configResult: AppConfig): void {
    freeviewTimePersistedStore(configResult.fp.time * 1000);
    setAppConfig(configResult);
  }

  useEffect(() => {
    fetchAppConfig();
  }, [fetchAppConfig]);

  useEffect(() => {
    if (appConfig && paramsCb) {
      const fetchData = async () => {
        try {
          await Promise.all([fetchControlsConfig(), fetchAdsConfig(), fetchBillboardsConfig()]);

          // TODO:  handle updating this state somewhere else so this doesn't have a side effect inside of it?
          appReady({ ...appReady(), initialQueriesComplete: true });
        } catch (e) {
          LOG.error('ERROR:', e);
        }
      };
      fetchData();
    }
  }, [appConfig, fetchAdsConfig, fetchBillboardsConfig, fetchControlsConfig, paramsCb]);

  // Hack for webos 4
  useForcedRefetch(fetchControlsConfig, appControlsFetchCounter);

  return {
    adsConfig,
    appConfig,
    appConfigUrl,
    appControlsFetchCounter,
    appControlsPollingEnabled,
    billboardsConfig,
    boxscoreFetchCounter,
    boxscorePollingEnabled,
    currentFetchCounter,
    currentPollingEnabled,
    device,
    setAdsConfig,
    setAppConfig,
    setAppConfigUrl,
    setAppControlsFetchCounter,
    setAppControlsPollingEnabled,
    setBillboardsConfig,
    setBoxscoreFetchCounter,
    setBoxscorePollingEnabled,
    setCurrentFetchCounter,
    setCurrentPollingEnabled,
    setWebOSDeviceInfo,
    webosDeviceInfo,
  };
}

function setControlsConfig(config: ControlsConfig): void {
  if (!arraysAreEqual(allowedEventsStore(), config[device].allow_events)) {
    allowedEventsStore(config[device].allow_events);
  }
  appConfigIndicatorStore(config[device].config);
  bracketIncompleteStore(config.states.bracket_incomplete);
  bracketLockStore(config.states.bracket_lock);
  connectedFullscreenStore(config.states.connected_fullscreen);
  connectedMultigameStore(config.states.connected_multigame);
  enableBracketSponsorStore(config.states.enable_bracket_sponsor);
  enableMultiGameStore(config.states.enable_multigame);
  excitementAlertsBrandedToastStore(config[device].excitement_alerts_branded_toast);
  gameSwitcherAllowedStore(config[device].gameswitcher);
  internalExcitementStore(config[device].internal_excitement);
  if (!arraysAreEqual(liveVideoCueIdStore() ?? [], config[device].video.live.cue_id)) {
    liveVideoCueIdStore(config[device].video.live.cue_id);
  }
  liveVideoCueStore(config[device].video.live.cue);
  montageChannelGuideStore(config[device].montage_channel_guide);
  montageSponsorStore(config.states.montage_sponsor);
  multiGameActiveStore(config[device].multigame.active);
  qrcodeCBSStore(config.states.qrcode_cbs);
  scheduleSlateStore(config.states.schedule_slate);
  if (device !== Device.lgtv) {
    sponsorMultiStore(config.states[`sponsor_multi_${device}`]);
  }
  statsActiveStore(config[device].stats.active);
  statsInactiveGamesStore(config[device].stats.inactive_games);
  tournamentYearStore(config.states.tournament_year);
  videoPlatformStore(config[device].video.platform as MediaJSONPlatform);
  videoAvalStore(config[device].video.aval);
  reportVideoErrorMetaStore(config[device].report_video_error_meta ?? false);
}
